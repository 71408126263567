
export const randNum = (min = 0, max = 100) :number =>
	Math.random() * ((max+1) - min) + min

export const clamp = (val :number, min :number, max :number) :number =>
	Math.min(Math.max(val, min), max)

export const randItem = <T>(lst :Array<T>) :T =>
	lst[Math.floor(Math.random()*lst.length)]

export const chunk = <T>(size :number, arr :T[]) :T[][] => {
	const result: T[][] = []
	for (let i = 0; i < arr.length; i += size) result.push(arr.slice(i, i + size))
	return result
}

export const verifyChars = (s :string) :boolean =>
	!hasChar(s, ' ;/\\|\'"`,°:§ªº´[]<>%$&#{}()*¨')

export const hasChar = (s :string, chars :string) :boolean => {
	for (const c of chars) if (s.includes(c)) return true
	return false
}

export const hasCharOnly = (s :string, chars :string) :boolean => {
	let result = true
	for (const c of s) if (!chars.includes(c)) result = false
	return result
}

export const starts = (s :string, _starts :Array<string>) :boolean =>
	_starts.map((st) => s.startsWith(st))
	       .reduce((a, b) => a || b)

export const ends = (s :string, _ends :Array<string>) :boolean =>
	_ends.map((st) => s.endsWith(st))
	       .reduce((a, b) => a || b)

